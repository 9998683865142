<template>
  <div class="landlords-hero">
    <div class="container">
      <h1>{{ $t("landlord") }}</h1>
      <h2 class="suppliers__subtitle">
        {{ $t("landlordText") }}
      </h2>
      <a class="btn" @click="scrollToVacancy">{{ $t("fillForm") }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandlordsHero",
  methods: {
    scrollToVacancy() {
      document
        .querySelector("#landlords-form")
        .scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
